<template>
  <div class="container-fluid bg-light px-0 py-3">
    <main class="container-xl" v-if="verSaitERP">
        <h1>Sait ERP</h1>
        <p class="fs-3">SAIT ERP es un Sistema Administrativo Integral que te ayudará a planificar los recursos de tu empresa de la manera más eficiente.</p>
        <p class="fs-5">Ideal para empresas pequeñas y medianas que requieren control específico sobre cada una de sus operaciones administrativas. Cumple con todas las normas fiscales establecidas por el SAT</p>
        <div class="row gx-0 mt-5 mb-lg-5 justify-content-sm-center">
          <div class="col-sm-auto col-lg-8">
            <img src="../assets/modulos_sait.jpg" alt="" class="img-fluid w-50">
          </div>
          <div class="col-sm-8 col-md-10 col-lg-4 m-sm-5 m-lg-0">
            <p class="fs-5 py-4">SAIT es un sistema de cómputo que le permite administrar y mejorar la organización de cualquier empresa.</p>
            <p class="fs-5 py-4">Su negocio necesita herramientas que le ayuden a crecer y a lograr sus metas, SAIT puede lograr eso y más.</p>
          </div>
        </div>
    </main>
    <main class="container-xl" v-else>
        <h1>Sait Básico</h1>
        <p class="fs-3">SAIT Básico es un punto de venta que se adapta al tamaño de tu empresa.</p>
        <p class="fs-5">Incluye factura electrónica, control de efectivo en caja, manejo de inventarios, cuentas por cobrar, enlace de sucursales, además cumple con todas las normas establecidas por el SAT</p>
    </main>
    <section class="bg-azul">
      <div class="container p-5">
          <div class="row justify-content-center">
            <p class="fs-5 w-75 text-light">Lo invitamos a que conozca y use SAIT durante 30 días sin ningún costo, y nos permita convencerle que tener una empresa bien organizada, solo es cuestión de SAIT</p>
          </div>
          <a href="#" v-if="verSaitERP" class="btn btn-success fw-bold me-2" @click.prevent="$emit('verFormDescarga',1)">Descargar</a>
          <a href="#" v-else class="btn btn-success fw-bold me-2" @click.prevent="$emit('verFormDescarga',2)">Descargar</a>
      </div>
    </section>
    <div class="d-flex justify-content-center">
      <TablaComparacionSait @verFormDescarga="relayVerForm"/>
    </div>
  </div>
</template>

<script>
import TablaComparacionSait from './TablaComparacionSait.vue'
// import FormDescarga from './FormDescarga.vue'

export default {
	components: { TablaComparacionSait },
  name: 'Sait-ERP',
  emits: ['verFormDescarga'],
  props: {verSaitERP: Boolean},
  methods: {
    relayVerForm(tipoProducto){
      this.$emit("verFormDescarga", tipoProducto);
    }
  }
}
</script>
<style scoped>
.bg-azul{
  background-color: #0a58ca;
}
.icono{
  font-size: 1.3rem;
}
.slide-enter-active, .slide-leave-active{
    transform: translateX(0);
    transition: transform ease-in 300ms;
}
.slide-enter-from, .slide-leave-to{
    transform: translateX(100px);
    transition: transform ease-in 300ms;
}

</style>
