<template>
  <div class="table-responsive w-75">
    <table class="table table-hover">
      <thead class="">
        <th scope="col"></th>
        <th scope="col"> <router-link class="nav-link" to="/sait-b">Sait Básico</router-link> </th>
        <th scope="col"> <router-link class="nav-link" to="/sait-erp">Sait ERP</router-link> </th>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Ventas</th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Compras</th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Inventario</th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Caja</th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Cobranza</th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Gastos</th>
          <td><i class="bi bi-x-circle-fill icono opacity-75 text-danger"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Cuentas por pagar</th>
          <td><i class="bi bi-x-circle-fill icono opacity-75 text-danger"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Bancos</th>
          <td><i class="bi bi-x-circle-fill icono opacity-75 text-danger"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Contabilidad</th>
          <td><i class="bi bi-x-circle-fill icono opacity-75 text-danger"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Módulos personalizados por giro
            <p class="fw-lighter">(carnicerías, ópticas, farmacias, etc.)</p>
          </th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row">Módulos especiales
            <p class="fw-lighter">(cliente frecuente, tarjeta de regalo, círculo de la salud)</p>
          </th>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
          <td><i class="bi bi-check-circle-fill icono"></i></td>
        </tr>
        <tr>
          <th scope="row"></th>
          <td><a href="#" class="btn btn-success fw-bold me-2" @click.prevent="$emit('verFormDescarga', 2)">Descargar</a></td>
          <td><a href="#" class="btn btn-success fw-bold me-2" @click.prevent="$emit('verFormDescarga', 1)">Descargar</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
    name: 'TablaComparacionSait',
    emits: ['verFormDescarga']
}
</script>
<style scoped>
.icono{
  font-size: 1.3rem;
}
</style>